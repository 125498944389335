var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("div", { style: { margin: "0 -3rem" } }, [
        _c("img", {
          attrs: {
            src: "img/membership/new-membership.jpg",
            alt: "멤버십 안내",
            width: "100%"
          }
        })
      ]),
      !_vm.isNewMembershipUser
        ? _c("footer-box", {
            attrs: {
              single: _vm.isTriedNewMembership,
              "btn-text": "30일 무료 이용",
              "btn-cb": function() {
                return _vm.tryFreeTrial()
              },
              "submit-text": "가입하기",
              "submit-cb": function() {
                return _vm.gotoPayment()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }