<template>
    <div class="container">
        <div :style="{ margin: '0 -3rem' }">
            <img
                src="img/membership/new-membership.jpg"
                alt="멤버십 안내"
                width="100%"
            />
        </div>

        <footer-box
            v-if="!isNewMembershipUser"
            :single="isTriedNewMembership"
            btn-text="30일 무료 이용"
            :btn-cb="() => tryFreeTrial()"
            submit-text="가입하기"
            :submit-cb="() => gotoPayment()"
        />
    </div>
</template>

<script>
import Icon from '@/components/common/Icon'
import FooterBox from '@/components/common/FooterBox'

const freeTrialModalBody = `
<span style="font-size: 24px;color: #000;">30일 무료 이용 시작</span>

<span style="font-size: 18px;">플러스 멤버십 무료 이용을
시작하시겠어요?

걱정마세요!
결제할 필요도 없고
한달 후에 자동으로 종료돼요.
</span>`

export default {
    name: 'NewMembership',
    components: {
        Icon,
        FooterBox,
    },
    data() {
        return {}
    },
    computed: {
        userData() {
            return this.$store.state.user.userData
        },
        artistData() {
            return this.$store.state.user.artistData
        },
        isTriedNewMembership() {
            return this.$store.getters.isTriedNewMembership
        },
        isNewMembershipUser() {
            return this.$store.getters.isNewMembershipUser
        },
        isApprovedProfile() {
            return this.$store.getters.isApprovedProfile
        },
    },
    methods: {
        withCheckApprovedProfile(cb) {
            if (!this.isApprovedProfile) {
                this.$toast('프로필 승인 후 이용이 가능합니다.')
                return
            }

            cb?.()
        },
        tryFreeTrial() {
            this.withCheckApprovedProfile(() => {
                this.$store.commit('setModal', {
                    show: true,
                    body: freeTrialModalBody,
                    textAlign: 'text-center',
                    confirm: '지금 바로 시작!',
                    confirmCb: modal => {
                        this.requestFreeTrial()
                        modal.hide()
                    },
                    noBorder: true,
                    cancel: '닫기',
                })
            })
        },
        requestFreeTrial() {
            const req = {
                method: 'post',
                url: `/artist/newMemberShipFreePost`,
            }

            return this.$http(req)
                .then(({ data }) => {
                    log(...Object.values(req), data)

                    const resultData = this.$lib.resultCheck(data.resultData)

                    if (resultData.success) {
                        this.$store.commit('setArtistData', {
                            newMemberDate: moment().add(30, 'd').valueOf().toString().slice(0, 10),
                        })
                        this.$router.back()
                    } else if (resultData.code === -10) {
                        this.$toast('무료체험을 신청할 수 없습니다.')
                    } else {
                        this.$alert(resultData)
                    }
                })
                .catch(log.error)
        },
        gotoPayment() {
            this.withCheckApprovedProfile(() => {
                this.$router.push('/new-membership/payment')
            })
        },
    },
}
</script>
